import * as React from "react"
import { PageProps } from "gatsby"
import styled, { css } from "styled-components"

import Seo from "../components/Seo"
import { PageHeading } from "./"

import { StoreContext } from "../context/store-context"
import CartLineItem from "../components/CartLineItem"
import { MdArrowForward } from "react-icons/md"
// import CartHolidayBanner from "../components/CartHolidayBanner"

const Cart: React.FC<PageProps> = () => {
  const { checkout, removeLineItem } = React.useContext(StoreContext)

  const [itemsLoaded, setItemsLoaded] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setItemsLoaded(true)
    }, 1500)
  }, [])

  let totalQuantity = 0
  checkout.lineItems?.forEach(({ quantity }) => (totalQuantity += quantity))

  const items = checkout ? checkout.lineItems : []

  const [showCheckoutButton, setShowCheckoutButton] = React.useState(false)

  React.useEffect(() => {
    setShowCheckoutButton(false)
    if (checkout?.lineItems?.length > 0) {
      setShowCheckoutButton(true)
    }
  }, [checkout])

  const handleCheckout = () => {
    // Link to checkout in same window. Fast solution for existing products in cart, when already ordered.
    location.href = checkout.webUrl
    // window.open(checkout.webUrl)
  }

  return (
    <CartContainer>
      <Seo title="Warenkorb" />
      <PageHeading>Warenkorb</PageHeading>

      {/* <CartHolidayBanner /> */}

      {items && (
        <div>
          {items.length >= 0 ? (
            items.map((item, index) => {
              if (item.variant === null) {
                removeLineItem(checkout.id, item.id)

                return (
                  <p style={{ textAlign: "center" }} key={index}>
                    Ein nicht mehr vorhandenes Produkt wurde aus Ihrem Warenkorb
                    gelöscht.
                  </p>
                )
              } else {
                return <CartLineItem key={item.id} item={item} />
              }
            })
          ) : (
            <div>Lade</div>
          )}
        </div>
      )}

      {showCheckoutButton ? (
        <>
          <Total>
            <h3>Summe:</h3>
            <p>{totalQuantity > 0 && ` (${totalQuantity} Artikel)`}</p>
            <span>
              {Intl.NumberFormat("de-DE", {
                currency: "EUR",
                minimumFractionDigits: 2,
                style: "currency",
              }).format(checkout?.subtotalPriceV2?.amount)}
            </span>
          </Total>

          <Button onClick={handleCheckout} style={{ marginTop: "2rem" }}>
            Zum Checkout
            <MdArrowForward />
          </Button>
        </>
      ) : itemsLoaded ? (
        <p style={{ marginTop: "4rem" }}>Der Warenkorb ist leer</p>
      ) : (
        <p>Lade Warenkorb...</p>
      )}
    </CartContainer>
  )
}

const CartContainer = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 2rem auto;
  text-align: center;
`

const Total = styled.div`
  h3 {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 0.5rem;
  }

  span {
    font-weight: bold;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-left: 0.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background: white;
    box-shadow: ${({ theme }) => theme.colors.primary} 0px 0px 0px 1px;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: inherit;
      color: #b4b4b4;
      border: 1px solid #b4b4b4;
      opacity: 0;

      :hover {
        box-shadow: none;
      }
    `}

  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export default Cart
